/* eslint-disable */
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { push } from 'redux-first-history'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import styled, { ThemeProvider } from 'styled-components'
import moment from 'moment'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { plugin as engagementPlugin } from '@amplitude/engagement-browser'
import * as amplitude from '@amplitude/analytics-browser'

import { withRouter } from 'hooks/router_state'

import { parseQueryString } from 'helpers/url'
import { isUserGated, isRouteGated, isFeatureGated } from 'helpers/permissions'
import { defaultFontFamily } from 'helpers/font'
import { getCookie } from 'services/cookies'

import { NO_PLAN_MODAL } from 'constants/account'
import {
  ACCESS_LIMIT_TYPE,
  GATED_FEATURE_EVENT,
  SET_FEATURE_LIMIT_EVENT
} from 'constants/account/permissions'
import { NOTIFICATION_STYLE } from 'constants/styles'

import { createNotification } from 'actions/notifications'

import {
  loadGlobalData,
  addLocationHistory,
  selectAmazonSellerAccount,
  updateActivePpcAccounts,
  setGlobalModal
} from 'actions/index'
import { loadChecklistData } from 'actions/checklist'
import {
  updateFeatureLimit,
  triggerFeatureLimitModal
} from 'actions/account/featureLimits'
import {
  toggleSidebar,
  toggleSidebarLocked,
  resetSidebar,
  expandSidebarOption
} from 'actions/sidebar'
import { fetchCardInfo } from 'actions/card_info'
import { updateLocale } from 'actions/international'
import { stopImpersonatingUser } from 'actions/impersonation'
import { logoutUser } from 'actions/sessions'
import { getAmazonSellerAccounts } from 'actions/amazon_seller_accounts'

import { mountSift } from 'services/sift'
import { toggleGuideInterval, togglePendoGuides } from 'services/pendo'

import AppComponent from '../components/app_component'

import { sendSegmentPageEvent } from 'services/segment'
import { changeLanguage } from 'services/i18n'
import { sendSegmentIdentifyWhenFullStoryReady } from 'helpers/segment'
import {
  intercomSnippet,
  loadLiveChat,
  updateLiveChat
} from 'services/intercom'
import { usabillaLiveInitializer } from 'services/usabilla_live'
import { setCurrentTheme, setEdnaLanguage } from '@junglescout/edna'
import {
  addIdentifyRequest,
  executeIdentifyRequests,
  identifyRequestsAreInProgress
} from 'actions/analytics'
import { DEFAULT_APP_TYPE, DEFAULT_THEME } from 'constants/themes'


const AppFontWrapper = styled.div`
  font-family: ${props => defaultFontFamily(props.lang)};
`

class App extends Component {
  _notificationSystem = null
  pendoInitializer = null

  constructor(props) {
    super(props)
    setCurrentTheme('default') // Set Edna theme before initial render
  }

  componentDidMount() {
    mountSift()

    // Pendo snippet is now being injected by Segment and, to disable
    // guides, we need to toggle the guides on some pages. We can't control
    // when Segment will inject the script, so we keep pooling. When
    // Pendo is installed we toggle the guides
    this.toggleGuidesIntRef = setInterval(
      () => toggleGuideInterval(this.toggleGuidesIntRef),
      500
    )

    // We don't want the toggle guide Pendo loop to be running forever
    // so, after 10s if Pendo is not injected we skip the configuration
    setTimeout(() => {
      clearInterval(this.toggleGuidesIntRef)
    }, 10000)

    this._notificationSystem = this.refs.notificationSystem
    const {
      globalData: {
        membershipInfo: { hasActiveMembership, group },
        featureLimits,
        user: { id: userId, checkout_locale }
      },
      amazonSellerAccounts: { amazonSellerAccounts },
      location
    } = this.props

    setCurrentTheme(DEFAULT_APP_TYPE) // Set EdnaTheme for the first render

    const { lang } = parseQueryString(location.search)
    const featureLimitsLoaded =
      featureLimits && Object.keys(featureLimits).length

    if (lang) {
      this.props.updateLocale(lang)
    }

    window.addEventListener(GATED_FEATURE_EVENT, this.handleGatedFeature)
    window.addEventListener(SET_FEATURE_LIMIT_EVENT, this.handleSetFeatureLimit)

    const locationPathname = location.pathname

    const isPathGated = isFeatureGated(locationPathname)
    const isFeatureGateHit = isPathGated

    if (getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)) {
      if (!hasActiveMembership && checkout_locale !== 'CN') {
        if (isRouteGated(locationPathname)) {
          this.props.setGlobalModal(NO_PLAN_MODAL)
        } else {
          this.props.setGlobalModal(null)
        }
      } else {
        if (
          featureLimitsLoaded &&
          isUserGated(featureLimits, locationPathname) &&
          !isFeatureGateHit
        ) {
          this.props.triggerFeatureLimitModal({ limit_type: ACCESS_LIMIT_TYPE })
        }
      }
    }     

    if (process.env.NODE_ENV === 'production') {
      // The sample rate is an estimation of our MAU divided by the number of available session replays we have in our account
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1
      })

      if( group === 'BrandOwner' || amazonSellerAccounts?.length > 0) {
        amplitude.add(sessionReplayTracking)
      }
      
      amplitude.add(engagementPlugin())

      amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, userId, {
        defaultTracking: true,
        optOut: false
      })
    }

    sendSegmentPageEvent(locationPathname)

    if (process.env.NODE_ENV !== 'test') {
      // fullstory integration; gets called if/when fullstory script is fully init
      this.fullstoryInitializer = setInterval(() => {
        sendSegmentIdentifyWhenFullStoryReady(userId, this.fullstoryInitializer)
      }, 500)
      this.intercomInitializer = setInterval(() => {
        intercomSnippet(this.intercomInitializer)
      }, 500)
      this.intercomBoot = setInterval(() => {
        loadLiveChat(this.intercomBoot)
      }, 500)
      this.usabillaInitializer = setInterval(() => {
        usabillaLiveInitializer(this.usabillaInitializer)
      }, 500)

      // stops and clears the setInterval above after 10 seconds; assumes FullStory won't load at all
      setTimeout(() => clearInterval(this.fullstoryInitializer), 10000)
      setTimeout(() => clearInterval(this.intercomInitializer), 10000)
      setTimeout(() => clearInterval(this.intercomBoot), 12000)
      setTimeout(() => clearInterval(this.usabillaInitializer), 12000)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      globalData: {
        initialLoad: { completed: isGlobalDataLoaded },
        membershipInfo: { hasActiveMembership },
        user: { checkout_locale, selected_language },
        featureLimits,
      },
      location
    } = this.props
    // add new notice only if the state of notices has changed
    if (
      prevProps.globalData.notices !== this.props.globalData.notices &&
      this.props.globalData.notices.length > 0
    ) {
      this._notificationSystem.addNotification(this.props.globalData.notices[0])
    }

    // change language if globalData value changes
    switch (selected_language) {
      case 'zh-Hant':
        moment.locale('zh-cn', require('moment/locale/zh-cn'))
        break
      case 'en-US':
        moment.locale('en-gb', require('moment/locale/en-gb'))
        break
      case 'de-DE':
        moment.locale('de', require('moment/locale/de'))
        break
      case 'es-ES':
        moment.locale('es', require('moment/locale/es'))
        break
      case 'fr-FR':
        moment.locale('fr', require('moment/locale/fr'))
        break
      case 'it-IT':
        moment.locale('it', require('moment/locale/it'))
        break
      case 'hi-IN':
        moment.locale('hi', require('moment/locale/hi'))
        break
      case 'ja-JP':
        moment.locale('ja', require('moment/locale/ja'))
        break
    }

    // Update moment locale to use Sunday as the first day of the week
    const currentLocale = moment.locale()
    moment.updateLocale(currentLocale || 'en', {
      week: {
        dow: 0, // set Sunday as the first day of the week
        doy: 6 // set the week containing Jan 1st as the first week of the year, https://github.com/moment/momentjs.com/issues/279
      }
    })

    if (
      selected_language &&
      selected_language !== prevProps.globalData.user.selected_language
    ) {
      changeLanguage(selected_language)
      setEdnaLanguage(selected_language)
    }

    const previousPathname = prevProps.location.pathname
    const locationPathname = location.pathname

    const isPathGated = isFeatureGated(locationPathname)
    const isFeatureGateHit = isPathGated

    if (
      (locationPathname !== previousPathname ||
        hasActiveMembership !==
          prevProps.globalData.membershipInfo.hasActiveMembership ||
        featureLimits !== prevProps.globalData.featureLimits) &&
      isGlobalDataLoaded
    ) {
      if (!hasActiveMembership && checkout_locale !== 'CN') {
        if (isRouteGated(locationPathname)) {
          this.props.setGlobalModal(NO_PLAN_MODAL)
        } else {
          this.props.setGlobalModal(null)
        }
      } else {
        if (isUserGated(featureLimits, locationPathname) && !isFeatureGateHit) {
          this.props.triggerFeatureLimitModal({ limit_type: ACCESS_LIMIT_TYPE })
        }
      }
    }

    if (locationPathname !== previousPathname) {
      sendSegmentPageEvent(locationPathname, previousPathname)
      togglePendoGuides(window.location.hash)
      updateLiveChat()
    }
  }

  componentWillUnmount() {
    window.removeEventListener(GATED_FEATURE_EVENT, this.handleGatedFeature)
    window.removeEventListener(
      SET_FEATURE_LIMIT_EVENT,
      this.handleSetFeatureLimit
    )
  }

  handleGatedFeature = event => {
    const locationPathname = this.props.location.pathname
    const isPathGated = isFeatureGated(locationPathname)
    const isFeatureGateHit = isPathGated

    if (isFeatureGateHit) return

    this.props.triggerFeatureLimitModal(event.detail)
  }

  handleSetFeatureLimit = event => {
    const { featureLimitKey, featureLimits } = event.detail

    this.props.updateFeatureLimit(featureLimitKey, featureLimits)
  }

  render() {
    const { i18n } = this.props

    return (
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={DEFAULT_THEME}>
          <AppFontWrapper lang={i18n?.language}>
            <AppComponent {...this.props} />
            <NotificationSystem
              ref="notificationSystem"
              style={NOTIFICATION_STYLE}
            />
          </AppFontWrapper>
        </ThemeProvider>
      </DndProvider>
    )
  }
}

const mapStateToProps = ({
  banners,
  globalData,
  amazonSellerAccounts,
  sessions,
  sidebar,
  intl,
  account,
  cardInfo
}) => {
  return {
    banners,
    cardInfo,
    globalData,
    amazonSellerAccounts,
    sessions,
    sidebar,
    intl,
    isStatusBarVisible: account.ui.mwsSync.statusBarVisible,
    account
  }
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        setGlobalModal,
        loadGlobalData,
        loadChecklistData,
        logoutUser,
        addLocationHistory,
        createNotification,
        stopImpersonatingUser,
        updateLocale,
        toggleSidebar,
        toggleSidebarLocked,
        resetSidebar,
        expandSidebarOption,
        push,
        selectAmazonSellerAccount,
        updateActivePpcAccounts,
        triggerFeatureLimitModal,
        updateFeatureLimit,
        fetchCardInfo,
        getAmazonSellerAccounts,
        addIdentifyRequest,
        executeIdentifyRequests,
        identifyRequestsAreInProgress
      }
    )(App)
  )
)
